interface AriaAttribute {
  ATTRIBUTE: string;
  VALUES: {
    [index: string]: string;
  };
}

export const ARIA: { EXPANDED: AriaAttribute } = {
  EXPANDED: {
    ATTRIBUTE: "aria-expanded",
    VALUES: {
      TRUE: "true",
      FALSE: "false",
    },
  },
};
