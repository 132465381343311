enum SentryLevel {
  Fatal = "fatal",
  Critical = "critical",
  Error = "error",
  Warning = "warning",
  Log = "log",
  Info = "info",
  Debug = "debug",
}

export const SENTRY = {
  LEVEL: SentryLevel,
};

export const ENVIRONMENT = {
  DEVELOPMENT: "development",
  RELEASE_CANDIDATE: "release-candidate",
  PRODUCTION: "production",
};
