import { ARIA } from "../utils/aria";

enum SEARCH_STATE {
  OPEN = "Open",
  CLOSED = "Closed",
}

declare interface MRCSearch {
  toggleButton: HTMLButtonElement;
  container: HTMLDivElement;
  screenReaderLabel: HTMLSpanElement;
  state: SEARCH_STATE;
  toggle: () => void;
}

export class Search implements MRCSearch {
  toggleButton: HTMLButtonElement;
  container: HTMLDivElement;
  screenReaderLabel: HTMLSpanElement;
  state: SEARCH_STATE;

  constructor({ toggleButton, container }) {
    this.toggleButton = toggleButton;
    this.container = container;

    // Computed properties
    this.screenReaderLabel = this.toggleButton.querySelector(".sr-only");
    this.state = SEARCH_STATE.CLOSED;

    // Binding methods
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const pagefindUiInput = <HTMLInputElement>(
      this.container.querySelector(".pagefind-ui__search-input")
    );

    if (this.state === SEARCH_STATE.CLOSED) {
      this.container.classList.remove("hide");

      requestAnimationFrame(() => {
        this.container.classList.add("show");

        // Update ARIA attribute
        this.toggleButton.setAttribute(
          ARIA.EXPANDED.ATTRIBUTE,
          ARIA.EXPANDED.VALUES.TRUE
        );

        requestAnimationFrame(() => {
          // Update focus
          pagefindUiInput.focus();

          // Update screen reader text
          this.screenReaderLabel.innerText = "Hide Search";

          // Update state
          this.state = SEARCH_STATE.OPEN;

          window.gtag("event", "search_open", {
            page: window.mrc.CURRENT_PAGE,
          });
        });
      });
    } else {
      this.container.classList.remove("show");

      requestAnimationFrame(() => {
        this.container.classList.add("hide");

        // Update state, ARIA attr, screen reader text
        this.toggleButton.setAttribute(
          ARIA.EXPANDED.ATTRIBUTE,
          ARIA.EXPANDED.VALUES.FALSE
        );
        this.screenReaderLabel.innerText = "Show Search";
        this.state = SEARCH_STATE.CLOSED;
      });
    }
  }
}
