const pagePathname = window.location.pathname.substr(
  0,
  window.location.pathname.indexOf("/", 1) + 1
);
const siteNav = document.getElementById("main-site-nav");
const [homeNavLink, ...restNavLinks] = Array.from(
  siteNav.querySelectorAll("a[href]")
);
const isHomePage = window.location.pathname === "/";

if (isHomePage) {
  homeNavLink.classList.add("active");
  homeNavLink.setAttribute("aria-current", "page");
  homeNavLink.setAttribute("aria-checked", "true");
} else {
  for (let link of restNavLinks) {
    const href = link.getAttribute("href");
    const pathPattern = new RegExp(`${href}/?$`);
    const pathMatch = pagePathname.match(pathPattern);

    if (pathMatch) {
      link.classList.add("active");
      link.setAttribute("aria-current", "page");
      link.setAttribute("aria-checked", "true");
    }
  }
}
