declare interface MRCMobileMenu {
  button: HTMLButtonElement;
  navigation: HTMLElement;
  linkList: HTMLAnchorElement[];
  focusableElements: (HTMLButtonElement | HTMLAnchorElement | EventTarget)[];
  state: MenuState;
}

enum MenuState {
  Open = "OPEN",
  Closed = "CLOSED",
}

export class MobileMenu implements MRCMobileMenu {
  button: HTMLButtonElement;
  navigation: HTMLElement;
  linkList: HTMLAnchorElement[];
  focusableElements: (HTMLButtonElement | HTMLAnchorElement | EventTarget)[];
  state: MenuState;

  constructor({
    button,
    navigation,
  }: {
    button: HTMLButtonElement;
    navigation: HTMLElement;
  }) {
    this.button = button;
    this.navigation = navigation;
    this.linkList = Array.from(this.navigation.querySelectorAll("a"));
    this.focusableElements = [this.button, ...this.linkList];
    this.state = MenuState.Closed;
  }

  setupEventListeners() {
    const skipToContentButton = document.getElementById("skip-to-content-btn");
    const siteContent = document.getElementById("main-content");
    const siteFooter = document.getElementById("site-footer");

    // Create reusable function for closing menu
    const closeMenu = () => {
      this.navigation.classList.remove("mobile-open");
      skipToContentButton.removeAttribute("aria-hidden");
      siteContent.removeAttribute("aria-hidden");
      siteFooter.removeAttribute("aria-hidden");

      setTimeout(() => {
        this.navigation.classList.remove("mobile-toggled");

        this.state = MenuState.Closed;
      }, 333);
    };

    if ("ResizeObserver" in window) {
      /**
       * If ResizeObserver exists, reate ResizeObserver instance to
       *  close menu when resizing over mobile breakpoint
       */
      const mobileResizeObserver = new ResizeObserver(
        (entries: ResizeObserverEntry[]) => {
          const bodyElement = <HTMLElement>entries[0].target;

          // console.log(bodyElement);

          if (bodyElement.offsetWidth > 600) {
            closeMenu();
          }
        }
      );

      // Start observing window resizing
      mobileResizeObserver.observe(window.document.body);
    }

    window.addEventListener("keydown", (e) => {
      if (this.state === MenuState.Open && e.key === "Escape") {
        // If user presses Escape key while menu is open, close it
        closeMenu();

        // Reset focus to button
        this.button.focus();
      }
    });

    // Setup button click event
    this.button.addEventListener("click", () => {
      if (this.state === MenuState.Closed) {
        this.navigation.classList.add("mobile-toggled");
        skipToContentButton.setAttribute("aria-hidden", String(true));
        siteContent.setAttribute("aria-hidden", String(true));
        siteFooter.setAttribute("aria-hidden", String(true));

        setTimeout(() => {
          this.navigation.classList.add("mobile-open");

          this.state = MenuState.Open;
        }, 0);
      } else {
        closeMenu();
      }
    });

    window.document.addEventListener("focusout", (focusoutEvent) => {
      if (this.state === MenuState.Open) {
        const {
          relatedTarget,
          target,
        }: { relatedTarget: EventTarget; target: EventTarget } = focusoutEvent;

        if (!this.focusableElements.includes(relatedTarget)) {
          const currentTargetIndex = this.focusableElements.indexOf(target);

          if (currentTargetIndex === this.focusableElements.length - 1) {
            this.button.focus();
          } else if (currentTargetIndex === 0) {
            this.linkList.at(-1).focus();
          }
        }
      }
    });
  }
}
